import React from 'react'
import ContactSection from '../components/ContactSection'
import Navbar from '../components/Navbar'
import Divider from '../components/Divider'
import Footer from '../components/Footer'
import SEO from '../components/seo'

function ContactPage(props) {
  return (
    <>
      <Navbar
        color="white"
        spaced={true}
        data={props.data.allContentfulNavbar}
      />
      <SEO title="Contact" />
      <ContactSection
        color="white"
        size="medium"
        title="Contact Us"
        subtitle=""
        showNameField={true}
        buttonText="Send message"
      />
      <Divider color="light" />
      <Footer
        color="light"
        size="medium"
        logo={props.data.allContentfulNavbar.edges[0].node.brandLogo}
        description={props.data.allContentfulFooter.edges[0].node.description}
        copyright={
          '© ' +
          props.data.allContentfulFooter.edges[0].node.companyName +
          ` ${new Date().getFullYear()}`
        }
        socialPages={props.data.allContentfulFooter.edges[0].node.socialPages}
        socialImages={props.data.allContentfulFooter.edges[0].node.socialImages}
        driftAppId={props.data.allContentfulFooter.edges[0].node.driftAppId}
      />
    </>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    allContentfulNavbar {
      edges {
        node {
          brandLogo {
            fixed(width: 250) {
              width
              height
              src
              srcSet
            }
            fluid(maxWidth: 1000) {
              src
            }
          }
          links
        }
      }
    }
    allContentfulHomePage {
      edges {
        node {
          title
          subtitle
          whyTitle
          whySubtitle
          bannerImage {
            fixed(width: 1000) {
              width
              height
              src
              srcSet
            }
            title
          }
          brands {
            file {
              url
            }
            title
          }
          childContentfulHomePageFeatureListJsonNode {
            feature {
              title
              subtitle
            }
          }
          featureImages {
            title
            fluid(maxWidth: 700) {
              ...GatsbyContentfulFluid
            }
          }
          stepNames
          step1
          step2
          step3
        }
      }
    }
    allContentfulStyles {
      edges {
        node {
          childContentfulStylesButtonJsonNode {
            backgroundColor
            color
          }
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          companyName
          description
          companyContactEmail
          driftAppId
          socialPages
          socialImages {
            title
            fixed(width: 20) {
              width
              height
              src
              srcSet
            }
          }
        }
      }
    }
  }
`
