import React from 'react'
import FormStatus from './../FormStatus'
import FormField from './../FormField'
import SectionButton from './../SectionButton'
import './styles.scss'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      showErrors: false,
      success: {
        type: '',
        message: '',
      },
    }
    this.setName = this.setName.bind(this)
    this.setEmail = this.setEmail.bind(this)
    this.setMessage = this.setMessage.bind(this)
    this.setShowErrors = this.setShowErrors.bind(this)
    this.setSuccess = this.setSuccess.bind(this)
  }

  // Error array we'll populate
  errors = []

  // Function for fetching error for a field
  getError = field => {
    return this.errors.find(e => e.field === field)
  }

  // Function to see if field is empty
  isEmpty = val => {
    return val.trim() === ''
  }

  setName = name => {
    this.errors = []
    this.setState({
      name: name,
    })
  }

  setEmail = email => {
    this.errors = []
    this.setState({
      email: email,
    })
  }

  setMessage = message => {
    this.errors = []
    this.setState({
      message: message,
    })
  }

  setShowErrors = showErrors => {
    this.setState({
      showErrors: showErrors,
    })
  }

  setSuccess = success => {
    this.setState({
      success: success,
    })
  }

  // Handle form submission
  handleSubmit = e => {
    if (this.isEmpty(this.state.email)) {
      this.errors.push({
        field: 'email',
        message: 'Please enter an email',
      })
    }

    // Add error if message empty
    if (this.isEmpty(this.state.message)) {
      this.errors.push({
        field: 'message',
        message: 'Please enter a message',
      })
    }

    // Add error if name shown and empty
    if (this.props.showNameField) {
      if (this.isEmpty(this.state.name)) {
        this.errors.push({
          field: 'name',
          message: 'Please enter your name',
        })
      }
    }
    // If field this.errors then show them
    if (this.errors.length) {
      this.setShowErrors(true)
      return
    } else {
      if (this.props.onSubmit) {
        this.props.onSubmit({
          status: 'pending',
        })
      }
      // Otherwise call onSubmit with form data
      fetch(`${process.env.GATSBY_EMAIL_ENDPOINT}/email`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'Contact',
          ...this.state,
        }),
      })
        .then(() => {
          alert('Thanks for the message. We will reach out to you shortly.')
          this.setSuccess({
            type: 'success',
            message: 'Successfully sent message.',
          })
          if (this.props.onSubmit) {
            this.props.onSubmit({
              status: 'success',
            })
          }
          // window.open('https://calendly.com/akutehealth/video-meeting-demo')
        })
        .catch(error => {
          // alert(error)
          // this.setSuccess({ type: 'error', message: 'Error sending message.' })
          alert('Thanks for the message. We will reach out to you shortly.')
          this.setSuccess({
            type: 'success',
            message: 'Successfully sent message.',
          })
          if (this.props.onSubmit) {
            this.props.onSubmit({
              status: 'success',
            })
          }
        })
    }
  }

  render() {
    return (
      <>
        {this.state.success && this.state.success.message && (
          <FormStatus
            type={this.state.success.type}
            message={this.state.success.message}
          />
        )}
        {this.state.success.type !== 'success' && (
          <form
            onSubmit={e => {
              e.preventDefault()
              this.handleSubmit()
            }}
            name={'Contact'}
            // data-netlify="true"
            // data-netlify-honeypot="bot-field"
            method="POST"
          >
            <div className="field is-horizontal">
              <div className="field-body">
                <input type="hidden" name="bot-field" />
                <input type="hidden" name="form-name" value={'Contact'} />
                {this.props.showNameField && (
                  <FormField
                    value={this.state.name}
                    type="text"
                    placeholder="Name"
                    error={this.state.showErrors && this.getError('name')}
                    onChange={value => this.setName(value)}
                  />
                )}

                <FormField
                  value={this.state.email}
                  type="email"
                  placeholder="Email"
                  error={this.state.showErrors && this.getError('email')}
                  onChange={value => this.setEmail(value)}
                />
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <FormField
                  value={this.state.message}
                  type="textarea"
                  placeholder="Message"
                  error={this.state.showErrors && this.getError('message')}
                  onChange={value => this.setMessage(value)}
                />
              </div>
            </div>
            <div className="field is-horizontal">
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <SectionButton
                      parentColor={this.props.parentColor}
                      size="medium"
                      state={
                        this.props.status &&
                        this.props.status.type === 'pending'
                          ? 'loading'
                          : 'normal'
                      }
                    >
                      {this.props.buttonText}
                    </SectionButton>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </>
    )
  }
}

export default ContactForm
